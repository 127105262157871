<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="subtitle-1">
        {{ item.text }}
        <v-tooltip v-if="tooltipHtml" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on" class="tooltip-icon"> mdi-information </v-icon>
          </template>
          <span v-html="tooltipHtml" />
        </v-tooltip>
      </v-list-item-title>

      <v-list-item-subtitle :class="`${item.class} subtitle-1 mt-1`">
        {{ item.value }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<style scoped>
.tooltip-icon {
  cursor: pointer;
}
</style>

<script>
export default {
  name: 'HoldingGeneralInfoItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tooltipHtml: (vm) => (vm.item.tooltip?.length
      ? vm.item.tooltip.map((el) => `<b>${el.text}:</b> ${el.value}`).join('<br />')
      : ''),
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  background: #000000d0;
}
</style>
