<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"

    :value="value"
    :items="items"
  >
    <template v-slot:prepend-item v-if="showSelectAll">
      <v-list-item @click="() => toggleSelectAll()">
        <v-list-item-action>
          <v-icon :color="selectAllIconColor">
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            Selecionar todos
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
    </template>
  </v-autocomplete>
</template>

<script>
import { isPlainObject } from 'lodash';

export default {
  name: 'FiltersAutocomplete',

  inheritAttrs: false,

  // NOTE: "value" must be set as a prop for v-model to work properly
  // @see (https://github.com/vuejs/vue/issues/8430)
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
    },

    showSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasSelectedAll: (vm) => vm.value.length === vm.items.length,
    hasSelectedSome: (vm) => vm.value.length > 0 && !vm.hasSelectedAll,
    selectAllIconColor: (vm) => (vm.value.length > 0 ? 'indigo darken-4' : ''),
    selectAllIcon: (vm) => {
      if (vm.hasSelectedAll) return 'mdi-close-box';
      if (vm.hasSelectedSome) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    toggleSelectAll() {
      if (this.hasSelectedAll) {
        this.$emit('input', []);
        this.$emit('change', []);
        return;
      }

      const itemValueIndex = this.$attrs['item-value'] ?? 'value';

      const returnArray = this.items.map((item) => (isPlainObject(item) ? item[itemValueIndex] : item));

      this.$emit('input', returnArray);
      this.$emit('change', returnArray);
    },
  },
};
</script>
