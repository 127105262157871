<template>
  <v-container fluid class="holdings-management">
    <h1 class="text-h5">Posições de ativos</h1>

    <holdings-filters :filters.sync="requestFilters" />

    <div class="text-right">
      <v-btn small rounded color="primary" class="mx-1" @click="() => fetchData()">
        <v-icon small left>
          mdi-refresh
        </v-icon>

        Atualizar
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        @click="() => isAnalysisPickerDialogOpen = true"
        v-if="isElevatedUser"
      >
        <v-icon small left>
          mdi-clipboard-pulse-outline
        </v-icon>

        Comparar carteiras
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        @click="() => isCalculationPickerDialogOpen = true"
        v-if="isElevatedUser"
      >
        <v-icon small left>
          mdi-calculator-variant-outline
        </v-icon>

        Calc. carteiras
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        @click="() => openOfficialHoldingsDialog()"
        v-if="isElevatedUser"
      >
        <v-icon small left>
          mdi-upload
        </v-icon>

        Importar XML
      </v-btn>
    </div>

    <holdings-overview
      :data="overviewData"
      :is-loading="isFetchingOverview"

      @click:analyze="(item) => analyzeHolding(item)"
      @click:calculate="(item) => calculatePortfolio(item)"
      @click:upload="() => openOfficialHoldingsDialog()"
    />

    <v-card>
      <v-card-text>
        <holdings-table
          fixed-header
          dense

          :items="results"
          :loading="isFetchingData"
          :readonly="!isElevatedUser"

          must-sort
          sort-by="refDate"
          sort-desc

          hide-default-footer
          :items-per-page="-1"

          @click:analyze="(item) => analyzeHolding(item)"
          @click:calculate="(item) => calculatePortfolio(item)"
          @click:upload="() => openOfficialHoldingsDialog()"
        />
        <v-row v-if="showLoadMore" class="my-2" align="center" justify="space-around">
          <v-btn :loading="isFetchingData" @click="() => loadMore()" small color="primary">Carregar mais</v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <holding-calculation-feedback
      v-model="isCalculationFeedbackDialogOpen"
      :selected-item="selectedItem"
      :loading="isCalculatingHoldings"
      :error-list="calculatingErrors"
    />

    <holding-analysis-feedback
      v-model="isAnalysisFeedbackDialogOpen"
      :selected-item="selectedItem"
      :loading="isAnalyzingHoldings"
      :status="analysisStatus"
      :error-list="analyzingErrors"
    >
      <template v-slot:actions-error>
        <v-card-actions class="justify-center pb-4">
          <v-btn
            outlined
            color="primary"
            :disabled="isApprovingHoldings"
            :loading="isApprovingHoldings"
            @click="() => approveHoldings()"
          >
            Aprovar com ressalvas
          </v-btn>

          <v-btn
            color="primary"
            :disabled="isApprovingHoldings"
            @click="isAnalysisFeedbackDialogOpen = false"
          >
            Aceitar
          </v-btn>
        </v-card-actions>
      </template>

      <template v-slot:actions-approved_with_warnings>
        <v-card-actions class="justify-center pb-4">
          <v-btn
            outlined
            color="error"
            :disabled="isApprovingHoldings"
            :loading="isApprovingHoldings"
            @click="() => reproveHoldings()"
          >
            Descartar ressalvas
          </v-btn>

          <v-btn
            color="primary"
            :disabled="isApprovingHoldings"
            @click="isAnalysisFeedbackDialogOpen = false"
          >
            Aceitar
          </v-btn>
        </v-card-actions>
      </template>
    </holding-analysis-feedback>

    <holding-period-picker-dialog
      title="Calcular carteiras"
      submit-btn-text="Calcular"
      :defaultFormFields.sync="requestFilters"
      v-model="isCalculationPickerDialogOpen"
      @submit="(fields) => calculatePortfolioPeriod(fields)"
    />

    <holding-period-picker-dialog
      title="Comparar carteiras"
      submit-btn-text="Comparar"
      :defaultFormFields.sync="requestFilters"
      v-model="isAnalysisPickerDialogOpen"
      @submit="(fields) => approveHoldingsPeriod(fields)"
    />

    <xml-upload-dialog
      max-width="600"
      v-model="isOfficialHoldingsDialogOpen"
      :loading="isImportingOfficialHoldings"
      @submit="(files) => bulkImportOfficialHoldings(files)"
    />
  </v-container>
</template>

<script>
import { debounce } from 'lodash';
import moment from 'moment-loyall';

import api from '@/services/api';
/* eslint-disable-next-line */
import { loadValueFromUrl, saveValuesOnUrl } from '@/utils/router-utils';

import { parseRequestFilters, getApiErrors } from './utils/request-utils';
import HoldingsFilters from './HoldingsFilters.vue';
import HoldingsOverview from './HoldingsOverview.vue';
import HoldingsTable from './HoldingsTable.vue';
import HoldingCalculationFeedback from './HoldingCalculationFeedback.vue';
import HoldingAnalysisFeedback from './HoldingAnalysisFeedback.vue';
import HoldingPeriodPickerDialog from './HoldingPeriodPickerDialog.vue';
import XmlUploadDialog from './XmlUploadDialog.vue';

export default {
  name: 'HoldingsManagementView',

  components: {
    HoldingsFilters,
    HoldingsOverview,
    HoldingsTable,
    HoldingCalculationFeedback,
    HoldingAnalysisFeedback,
    HoldingPeriodPickerDialog,
    XmlUploadDialog,
  },

  data: () => ({
    requestFilters: {
      houseFundIds: loadValueFromUrl('houseFundIds')?.split(',') ?? [],
      initialDate: loadValueFromUrl('initialDate') ?? moment().businessSubtract(5, 'days', 'brasil').format('YYYY-MM-DD'),
      finalDate: loadValueFromUrl('finalDate') ?? null,
    },

    results: [],
    total: 0,
    isFetchingData: false,

    overviewData: null,
    isFetchingOverview: false,

    isCalculationPickerDialogOpen: false,
    isAnalysisPickerDialogOpen: false,

    isCalculationFeedbackDialogOpen: false,
    isCalculatingHoldings: false,
    calculatingErrors: [],

    isAnalysisFeedbackDialogOpen: false,
    isAnalyzingHoldings: false,
    analysisStatus: 'IDLE',
    analyzingErrors: [],

    selectedItem: null,
    isApprovingHoldings: false,

    isOfficialHoldingsDialogOpen: false,
    isImportingOfficialHoldings: false,
  }),

  computed: {
    isElevatedUser: (vm) => ['admin', 'backoffice'].includes(vm.$store.state.user?.acl),
    showLoadMore: (vm) => vm.total > vm.results.length,
  },

  watch: {
    requestFilters: {
      deep: true,
      immediate: true,
      handler(newFilters) {
        const houseFundIds = newFilters.houseFundIds.join(',');
        saveValuesOnUrl({ ...newFilters, houseFundIds });

        this.fetchData();
      },
    },

    isAnalysisFeedbackDialogOpen(newState) {
      if (!newState) {
        this.selectedItem = null;
      }
    },
  },

  created() {
    this.$store.dispatch('houseFunds/fetchList');
  },

  methods: {
    fetchData: debounce(function debouncedFetchData() {
      this.fetchListingData();
      this.fetchOverviewData();
    }, 200),

    openOfficialHoldingsDialog() {
      this.isOfficialHoldingsDialogOpen = true;
    },

    async loadMore() {
      this.isFetchingData = true;

      try {
        const { data } = await api.holdings.getHoldingsList({
          params: {
            ...parseRequestFilters(this.requestFilters),
            offset: this.results.length,
          },
        });
        this.total = data.total;
        this.results = [...this.results, ...data.results] ?? [];
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    async fetchListingData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.holdings.getHoldingsList({
          params: parseRequestFilters(this.requestFilters),
        });
        this.total = data.total;
        this.results = data.results;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', { errorList: getApiErrors(error) });
      } finally {
        this.isFetchingData = false;
      }
    },

    async fetchOverviewData() {
      this.isFetchingOverview = true;

      try {
        const { data } = await api.holdings.getHoldingsOverview({
          params: parseRequestFilters(this.requestFilters),
        });

        this.overviewData = data;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', { errorList: getApiErrors(error) });
      } finally {
        this.isFetchingOverview = false;
      }
    },

    async calculatePortfolio(holdingObj) {
      this.isCalculatingHoldings = true;
      this.isCalculationFeedbackDialogOpen = true;
      this.calculatingErrors = [];
      this.selectedItem = holdingObj;

      try {
        const refDate = holdingObj.refDate.substring(0, 10);

        const { data } = await api.holdings.calculatePortfolio(holdingObj.houseFundId, refDate).catch((e) => {
          const { response } = e;

          if (response?.status === 400 && response.data.code === 'ERR_PARTIALLY_CALCULATED') {
            return response;
          }

          throw e;
        });

        const {
          status, netValue, netPrice, quantity, dailyReturn,
        } = data;

        const index = this.results.findIndex((el) => el._id === holdingObj._id);

        this.results[index] = {
          ...this.results[index],
          status,
          netValue,
          netPrice,
          quantity,
          dailyReturn,
        };

        this.results = [...this.results];

        if (status === 'PARTIALLY_CALCULATED') {
          this.selectedItem = holdingObj;
          this.calculatingErrors = data.meta.errorList ?? [];
        }
      } catch (error) {
        // Select item again in case other request was in progress
        this.selectedItem = holdingObj;
        this.calculatingErrors = getApiErrors(error);
      } finally {
        this.isCalculatingHoldings = false;
      }
    },

    async calculatePortfolioPeriod({ houseFundIds, initialDate, finalDate } = {}) {
      this.$store.dispatch('alert/showAlert', {
        icon: 'mdi-calculator-variant-outline',
        iconColor: 'grey',
        loading: true,
        title: 'Solicitando cálculo',
        message: 'Solicitando cálculo automático das carteiras.',
      });

      try {
        await api.holdings.calculatePortfolioPeriod({
          initialDate,
          finalDate: finalDate ?? initialDate,
          houseFundIds: houseFundIds?.length ? houseFundIds : null,
        });

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check',
          iconColor: 'success',
          title: 'Cálculo em progresso',
          message: 'O sistema está calculando as carteiras para o período solicitado.',
        });

        this.isCalculationPickerDialogOpen = false;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível calcular as carteiras.',
          errorList: getApiErrors(error),
        });
      }
    },

    async analyzeHolding(holdingObj) {
      this.isAnalyzingHoldings = true;
      this.analyzingErrors = [];
      this.analysisStatus = 'LOADING';
      this.selectedItem = holdingObj;
      this.isAnalysisFeedbackDialogOpen = true;

      try {
        const refDate = holdingObj.refDate.substring(0, 10);

        const { data } = await api.holdings.compareHoldings(holdingObj.houseFundId, refDate);

        this.analyzingErrors = data.errorsList ?? [];
        this.analysisStatus = data.status;
      } catch (error) {
        // Select item again in case other request was in progress
        this.selectedItem = holdingObj;
        this.analysisStatus = 'ERROR';
        this.analyzingErrors = getApiErrors(error);
      } finally {
        this.isAnalyzingHoldings = false;
        this.fetchData();
      }
    },

    async approveHoldings() {
      this.isApprovingHoldings = true;

      try {
        await api.holdings.approveHoldings(this.selectedItem?._id, this.analyzingErrors);
        this.isAnalysisFeedbackDialogOpen = false;
        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível aprovar a posição com ressalvas.',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isApprovingHoldings = false;
      }
    },

    async reproveHoldings() {
      this.isApprovingHoldings = true;

      try {
        await api.holdings.reproveHoldings(this.selectedItem?._id);
        this.isAnalysisFeedbackDialogOpen = false;
        this.fetchData();
      } catch (error) {
        const errorList = getApiErrors(error);

        this.errorTitle = 'Algo de errado aconteceu!';
        this.errorMessage = 'Não foi possível descartar as ressalvas.';
        this.errorList = errorList;
        this.isErrorDialogOpen = true;
      } finally {
        this.isApprovingHoldings = false;
      }
    },

    async approveHoldingsPeriod({ houseFundIds, initialDate, finalDate } = {}) {
      this.$store.dispatch('alert/showAlert', {
        icon: 'mdi-clipboard-pulse-outline',
        iconColor: 'grey',
        loading: true,
        title: 'Solicitando análise',
        message: 'Solicitando análise automática das carteiras.',
      });

      try {
        await api.holdings.compareHoldingsPeriod({
          initialDate,
          finalDate: finalDate ?? initialDate,
          houseFundIds: houseFundIds?.length ? houseFundIds : null,
        });

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check',
          iconColor: 'success',
          title: 'Análise em progresso',
          message: 'O sistema está analisando as carteiras para o período solicitado.',
        });

        this.isAnalysisPickerDialogOpen = false;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível comparar as carteiras.',
          errorList: getApiErrors(error),
        });
      }
    },

    async importOfficialHoldings(file) {
      try {
        const xmlString = await file.text();
        const res = await api.holdings.importOfficialHoldings(xmlString);
        return { fileName: file.name, res };
      } catch (error) {
        return { fileName: file.name, res: error?.response };
      }
    },

    createImportResultsBulkAlert(responses) {
      const allEqualBy = (arr, key) => arr.every((el) => el[key] === arr[0][key]);

      const getAlertMessages = (status) => ({
        success: {
          title: 'Todos XMLs importados',
          subtitle: 'Cada um dos arquivos foi importado com sucesso',
        },
        warning: {
          title: 'Alguns XMLs importados',
          subtitle: 'Nem todos os arquivos puderam ser importados corretamente',
        },
        error: {
          title: 'Nenhum XML importado',
          subtitle: 'Todos os arquivos falharam ao importar!',
        },
      }[status]);

      const data = responses.map(({ value: v }) => ({
        ...v.res?.status < 400
          ? { icon: 'mdi-check', iconColor: 'success' }
          : { icon: 'mdi-close', iconColor: 'error' },
        status: v.res?.status,
        label: v.fileName,
        details: v.res?.data?.message,
        errorList: v.res?.data?.meta?.errorList ?? [],
      }));

      const alertIconProps = allEqualBy(data, 'icon')
        ? { icon: data[0].icon, iconColor: data[0].iconColor }
        : { icon: 'mdi-exclamation', iconColor: 'warning' };

      return {
        ...alertIconProps,
        ...getAlertMessages(alertIconProps.iconColor),
        data,
      };
    },

    async bulkImportOfficialHoldings(files) {
      this.isImportingOfficialHoldings = true;

      const responses = await Promise.allSettled(files.map(
        (el) => this.importOfficialHoldings(el),
      ));
      const bulkAlertContent = this.createImportResultsBulkAlert(responses);

      this.isOfficialHoldingsDialogOpen = false;
      this.$store.dispatch('bulkAlert/showBulkAlert', bulkAlertContent);

      this.fetchData();
      this.isImportingOfficialHoldings = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>
