<template>
  <contextual-dialog
    v-bind="$attrs"
    v-on="$listeners"

    scrollable
    :value="value"
    :loading="loading"
    :max-width="hasError ? 600 : 400"

    :icon="icon"
    :icon-color="iconColor"
    :title="title"
    :message="message"
  >
    <template v-if="selectedItem">
      <p>Ref: {{ houseFundInfo }}</p>
    </template>

    <template v-if="hasError">
      <v-divider class="mx-4" />

      <v-card-text class="text-left">
        <template v-for="(error, index) of errorList">
          <p :key="index" class="text-body-2 mb-0 mt-4 px-4">
            <v-icon color="error" left>
              mdi-alert-circle-outline
            </v-icon>

            <span style="vertical-align: middle">
              {{ error }}
            </span>
          </p>
        </template>
      </v-card-text>
    </template>
  </contextual-dialog>
</template>

<script>
import ContextualDialog from '@/components/global/ContextualDialog.vue';

export default {
  name: 'HoldingCalculationFeedback',

  components: {
    ContextualDialog,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    errorList: {
      type: Array,
      default: () => [],
    },

    selectedItem: {
      type: Object,
      default: null,
    },
  },

  computed: {
    hasError: (vm) => vm.errorList?.length > 0,
    houseFundInfo: (vm) => `${vm.selectedItem?.shortName} ${vm.selectedItem?.refDate.substring(0, 10)}`,

    status: (vm) => {
      if (!vm.value) return 'idle';
      if (vm.loading) return 'loading';
      if (vm.hasError) return 'error';
      return 'success';
    },

    icon: (vm) => {
      switch (vm.status) {
        case 'loading': return 'mdi-calculator-variant-outline';
        case 'success': return 'mdi-check';
        case 'error': return 'mdi-exclamation-thick';
        default: return '';
      }
    },

    iconColor: (vm) => {
      switch (vm.status) {
        case 'loading': return 'grey';
        case 'success': return 'success';
        case 'error': return 'error';
        default: return '';
      }
    },

    title: (vm) => {
      switch (vm.status) {
        case 'loading': return 'Calculando carteira';
        case 'success': return 'Carteira calculada';
        case 'error': return 'Erro ao calcular';
        default: return '';
      }
    },

    message: (vm) => {
      switch (vm.status) {
        case 'loading': return 'Aguarde enquanto a carteira é calculada...';
        case 'success': return 'A carteira foi calculada com sucesso!';
        case 'error': return 'Não foi possível calcular a carteira corretamente.';
        default: return '';
      }
    },
  },
};
</script>
