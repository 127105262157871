<template>
  <v-card>
    <v-card-title>
      Status:

      <holdings-status-indicator class="ml-2" type="chip" small label :status="details.status" />
    </v-card-title>

    <v-card-subtitle> ID: {{ details._id }} </v-card-subtitle>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="3" v-for="(item, index) in items.slice(0, itemLimit)" :key="index">
          <v-list two-line dense>
            <holding-general-info-item :item="item" />
          </v-list>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row no-gutters v-show="showMore">
          <v-col cols="12" sm="3" v-for="(item, index) in items.slice(itemLimit)" :key="index">
            <v-list two-line dense>
              <holding-general-info-item :item="item" />
            </v-list>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>

    <v-divider />

    <v-btn block small text color="primary" @click="showMore = !showMore">
      {{ showMore ? "Ver menos" : "Ver mais" }}
    </v-btn>
  </v-card>
</template>

<style scoped>
.tooltip-icon {
  cursor: pointer;
}
</style>

<script>
import {
  formatMoney,
  formatNumber,
  formatPercentage,
  formatTitle,
} from '@/utils/format-utils';

import Decimal from 'decimal.js';
import { isNil } from 'lodash';
import HoldingGeneralInfoItem from './HoldingGeneralInfoItem.vue';
import HoldingsStatusIndicator from './HoldingsStatusIndicator.vue';

const formatReturn = (val) => formatPercentage(val, { maximumFractionDigits: 2 });
const formatQuota = (val) => formatNumber(val, { minimumFractionDigits: 8 });

export default {
  name: 'HoldingGeneralInfo',

  components: {
    HoldingsStatusIndicator,
    HoldingGeneralInfoItem,
  },

  props: {
    details: {
      type: Object,
      required: true,
    },

    riskData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showMore: false,
    itemLimit: 12,
  }),

  computed: {
    formattedCashHoldings: (vm) => vm.details.cashHoldings?.cashHoldingsList
      .map((el) => `${formatMoney(el?.currencyValue)} ${el?.currency}`)
      .join(' | '),

    collateralUsagePercentage: (vm) => (!isNil(vm.details.requiredCollateral) && vm.details.coveredCollateral > 0
      ? Decimal(vm.details.requiredCollateral).div(vm.details.coveredCollateral).toNumber()
      : null),

    netCollateral: (vm) => (!isNil(vm.details.requiredCollateral) && !isNil(vm.details.coveredCollateral)
      ? Decimal(vm.details.coveredCollateral).sub(vm.details.requiredCollateral).toNumber()
      : null),

    allStressScenarios: (vm) => vm.riskData.stressData?.map((el) => ({
      text: formatTitle(el.scenarioName),
      value: formatReturn(el.percentageStress),
    })),

    liquidityHorizon: (vm) => vm.riskData.liquidityData?.map((el) => ({
      text: `D+${el.referenceDate}`,
      value: formatMoney(el.cashProvided),
    })),

    isOpening: (vm) => !!(vm.details.openingPrice ?? vm.details.openingNetValue),

    closurePrice: (vm) => vm.details.price ?? vm.details.calculatedPrice,
    closureNetValue: (vm) => vm.details.netValue ?? vm.details.calculatedNetValue,

    items: (vm) => [
      {
        text: 'Patrimônio líquido',
        value: formatMoney(vm.isOpening ? vm.details.openingNetValue : vm.closureNetValue),
        tooltip: [{ text: 'P&L diário', value: formatMoney(vm.details.dailyPnL) }],
      },

      {
        text: 'Retorno diário',
        value: formatReturn(vm.details.dailyReturn),
        class: vm.details.dailyReturn < 0 ? 'error--text' : 'success--text',
      },

      {
        text: 'MTD',
        value: formatReturn(vm.details.mtd),
        class: vm.details.mtd < 0 ? 'error--text' : 'success--text',
      },

      {
        text: 'YTD',
        value: formatReturn(vm.details.ytd),
        class: vm.details.ytd < 0 ? 'error--text' : 'success--text',
      },

      {
        text: `Cota${vm.isOpening ? ' de abertura' : ''} líquida`,
        value: formatQuota(vm.isOpening ? vm.details.openingPrice : vm.closurePrice),
        tooltip: [
          ...(vm.isOpening
            ? [{ text: 'Cota de fechamento líquida', value: formatQuota(vm.closurePrice) }]
            : []),
          {
            text: 'Ajuste',
            value: formatMoney(vm.details.netAdjustmentValue),
          },
        ],
      },

      {
        text: 'Quantidade de cotas',
        value: formatNumber(vm.details.shareQuantity),
        class: vm.details.shareQuantity < 0 ? 'error--text' : '',
        tooltip: [
          { text: 'Movimentações do dia', value: formatMoney(vm.details.dailyOperationsValue) },
          {
            text: 'Movimentações oficiais do dia',
            value: formatMoney(vm.details.officialDailyOperationsValue),
          },
        ],
      },

      {
        text: 'Prazo médio',
        value: formatNumber(vm.details.averageMaturity),
      },

      {
        text: 'Taxa de performance',
        value: formatNumber(vm.details.performanceFee),
        class: vm.details.performanceFee < 0 ? 'success--text' : 'error--text',
        tooltip: [
          { text: 'Cota bruta', value: formatQuota(vm.details.grossPrice) },
          { text: 'Ajuste', value: formatMoney(vm.details.grossAdjustmentValue) },
        ],
      },

      {
        text: 'Fechamento de caixa',
        value: vm.formattedCashHoldings,
        class: vm.details.cashHoldings?.value < 0 ? 'error--text' : '',
        tooltip: [
          {
            text: `Total em ${vm.details.cashHoldings?.currency}`,
            value: formatMoney(vm.details.cashHoldings?.value),
          },
        ],
      },

      {
        text: 'VaR 95',
        value: formatReturn(vm.riskData.varData?.percentageVaR),
      },

      {
        text: 'Stress',
        value: formatReturn(vm.riskData.stressData?.[0]?.percentageStress),
        tooltip: vm.allStressScenarios,
      },

      {
        text: 'Liquidez D+1',
        value: formatMoney(vm.riskData.liquidityData?.[0]?.cashProvided),
        tooltip: vm.liquidityHorizon,
      },

      {
        text: 'P&L diário',
        value: formatMoney(vm.details.dailyPnL),
        class: vm.details.dailyPnL < 0 ? 'error--text' : 'success--text',
      },

      {
        text: 'Patrimônio bruto',
        value: formatMoney(vm.details.grossValue),
      },

      {
        text: 'Patrimônio líquido abertura',
        value: formatMoney(vm.isOpening ? vm.details.openingNetValue : vm.details.openNetValue),
      },

      {
        text: 'Margem consumida',
        value: formatPercentage(vm.collateralUsagePercentage),
        class: vm.collateralUsagePercentage > 0.8 ? 'error--text' : '',
        tooltip: [
          { text: 'Depositado', value: formatMoney(vm.details.coveredCollateral) },
          { text: 'Requerido', value: formatMoney(vm.details.requiredCollateral) },
          { text: 'Balanço', value: formatMoney(vm.netCollateral) },
        ],
      },
    ],
  },
};
</script>
