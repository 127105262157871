<template>
  <v-card>
    <v-card-title>
      Exposição x Book

      <v-spacer />
      <v-badge
        overlap
        color="info"
        :content="filterCount"
        :value="filterCount > 0"
      >
        <v-btn
          small
          rounded
          color="primary"
          @click="isFiltersDialogOpen = true"
        >
          <v-icon small left> mdi-filter </v-icon>

          Filtros
        </v-btn>
      </v-badge>

    </v-card-title>

    <v-card-subtitle> Clique no box para expandir os detalhes </v-card-subtitle>

    <div class="px-4 pb-4">
      <v-container
        fluid
        class="overview-container"
        @click="areDetailsExpanded = !areDetailsExpanded"
      >
        <v-row no-gutters>
          <v-col cols="12" sm="10">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title> Registros </v-list-item-title>
                <v-list-item-subtitle>
                  {{ filteredItems.length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="2">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title> Exposição total </v-list-item-title>
                <v-list-item-subtitle
                  :class="{ 'error--text': overview.totalExposure < 0 }"
                >
                  {{ formatMoney(overview.totalExposure) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-expand-transition>
      <div v-if="areDetailsExpanded">
        <holding-exposure-chart :v-if="areDetailsExpanded" :items="filteredItems" :netValue="netValue" />
      </div>
    </v-expand-transition>

    <v-dialog v-model="isFiltersDialogOpen" max-width="400">
      <v-card>
        <v-card-title> Filtros </v-card-title>

        <v-container fluid class="pt-0">
          <v-row>
            <v-col cols="12">
              <filters-autocomplete
                label="Book"
                hide-details
                show-select-all
                multiple
                clearable
                small-chips
                deletable-chips
                :items="filterOptions.book"
                v-model="filters.book"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn small color="primary" @click="isFiltersDialogOpen = false">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { sumBy, uniq } from 'lodash';
import { formatMoney } from '@/utils/format-utils';

import HoldingExposureChart from './HoldingExposureChart.vue';
import FiltersAutocomplete from './FiltersAutocomplete.vue';

export default {
  name: 'HoldingExposure',

  components: {
    HoldingExposureChart,
    FiltersAutocomplete,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    netValue: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    areDetailsExpanded: false,
    isFiltersDialogOpen: false,

    filters: {
      book: [],
    },
  }),

  computed: {
    filterCount: (vm) => Object.values(vm.filters).filter(
      (val) => (Array.isArray(val) ? val.length > 0 : !!val),
    ).length,

    filterOptions: (vm) => ({
      book: uniq(vm.items.map((item) => item.book)).sort(),
    }),

    filteredItems: (vm) => vm.items.filter(
      (item) => ([!vm.filters.book.length || vm.filters.book.includes(item.book)].every(Boolean)),
    ),

    overview: (vm) => ({
      totalExposure: sumBy(vm.filteredItems, 'value'),
    }),
  },

  methods: {
    formatMoney,
  },
};
</script>

<style scoped lang="scss">
.overview-container {
  background: #eeee;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}
</style>
