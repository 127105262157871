<template>
  <div>
    <apexchart ref="exposureChart" :height="chartHeight" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import Vue from 'vue';
import Decimal from 'decimal.js';
import pt from 'apexcharts/dist/locales/pt-br.json';
import { formatPercentage } from '@/utils/format-utils';

import HoldingExposureChartTooltip from './HoldingExposureChartTooltip.vue';

export default {
  name: 'HoldingExposureChart',

  components: {
    // Rule only checks template, component used in chart options
    // eslint-disable-next-line vue/no-unused-components
    HoldingExposureChartTooltip,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    netValue: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    chartAnimationTime: 800,
  }),

  computed: {
    chartData: (vm) => vm.items.map((exposure) => ({
      ...exposure,
      percentValue: Decimal(exposure.value).div(vm.netValue),
    })),

    series: (vm) => [{ data: vm.chartData.map((item) => item.percentValue) }],

    // 36px for each bar + 60px for other elements (axis, labels, ...)
    chartHeight: (vm) => Decimal(vm.items.length ?? 0).times(36).add(60).toNumber(),

    chartMax: (vm) => {
      const max = Math.max(...vm.chartData.map((item) => Number(item.percentValue)));
      // Arbitrary values by trial and error
      const relativePadding = 1.2;
      const absolutePadding = 0.2;

      return Decimal(max).times(relativePadding).add(absolutePadding).toNumber();
    },

    chartMin: (vm) => {
      const min = Math.min(...vm.chartData.map((item) => Number(item.percentValue)));
      // Arbitrary values by trial and error
      const relativePadding = 1.2;
      const absolutePadding = 0.2;

      return min < 0
        ? Decimal(min).times(relativePadding).sub(absolutePadding).toNumber()
        : 0;
    },

    options: (vm) => ({
      chart: {
        animations: {
          easing: 'easeinout',
          speed: vm.chartAnimationTime,
          animateGradually: {
            enabled: true,
            delay: 1,
          },
          dynamicAnimation: {
            enabled: true,
            speed: vm.chartAnimationTime,
          },
        },
        events: {
          animationEnd: () => {
            vm.$refs.exposureChart.updateOptions({
              // Clears event to ignore further triggers
              chart: {
                events: {
                  animationEnd: () => ({}),
                },
              },
              dataLabels: { enabled: true },
            });
          },
        },
        defaultLocale: 'pt-br',
        locales: [pt],
        fontFamily: 'Roboto, Arial, sans-serif',
        toolbar: { show: false },
        type: 'bar',
        zoom: { enabled: false },

      },
      dataLabels: {
        enabled: false, // Enabled after animation ends
        formatter: formatPercentage,
        offsetX: 110,
        style: {
          fontWeight: 600,
          colors: ['#eee'],
        },
        background: {
          enabled: true,
          foreColor: '#18324f',
          opacity: 1,
          padding: 10,
          borderWidth: 0,
          borderRadius: 2,
        },
      },
      fill: {
        type: 'gradient',
        colors: ['#18324f'],
        opacity: 1,
        gradient: {
          shadeIntensity: 0.2,
          inverseColors: true,
          stops: [0, 50],
        },
      },
      grid: {
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: false } },
        column: { colors: ['#eee', '#fff'] },
        padding: { left: 20, right: 25 },
      },
      legend: { show: false },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 2,
          dataLabels: {
            position: 'top',
          },
        },
      },
      tooltip: {
        custom: ({ dataPointIndex }) => {
          const tooltip = new Vue({
            render: (h) => h(HoldingExposureChartTooltip, {
              props: {
                data: vm.chartData[dataPointIndex],
                netValue: vm.netValue,
              },
            }),
          });
          tooltip.$mount();
          return tooltip.$el.outerHTML;
        },
        fixed: {
          enabled: true,
          position: 'bottomRight',
          offsetX: -18,
          offsetY: -5,
        },
      },
      xaxis: {
        categories: vm.chartData.map((item) => item.book),
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: { show: false },
        tickAmount: 12,
        min: vm.chartMin,
        max: vm.chartMax,
      },
    }),
  },
};
</script>
