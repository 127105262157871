<template>
  <v-card>
    <v-card-title>
      Empréstimo de Ações

      <v-spacer />

      <v-badge overlap color="info" :content="filterCount" :value="filterCount > 0">
        <v-btn small rounded color="primary" @click="isFiltersDialogOpen = true">
          <v-icon small left>
            mdi-filter
          </v-icon>

          Filtros
        </v-btn>
      </v-badge>
    </v-card-title>

    <v-card-subtitle>
      Clique no box para expandir os detalhes
    </v-card-subtitle>

    <div class="px-4 pb-4">
      <v-container fluid class="overview-container" @click="areDetailsExpanded = !areDetailsExpanded">
        <v-row no-gutters>
          <v-col cols="12" sm="auto">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Registros
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ filteredItems.length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="auto">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Quantidade líquida
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalNetQuantity < 0 }">
                  {{ formatNumber(overview.totalNetQuantity) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="auto">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Quantidade bruta
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalGrossQuantity < 0 }">
                  {{ formatNumber(overview.totalGrossQuantity) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="auto">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Taxa acruada total
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalAccruedFees < 0 }">
                  {{ formatMoney(overview.totalAccruedFees) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- <v-col cols="12" sm="auto">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Atrib. perf. Mensal
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalMtdPerf < 0 }">
                  {{ formatPerformance(overview.totalMtdPerf) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="auto">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Atrib. perf. Anual
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalYtdPerf < 0 }">
                  {{ formatPerformance(overview.totalYtdPerf) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col> -->

          <v-col cols="12" sm="auto">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  %PL total
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalPercValue < 0 }">
                  {{ formatPercentage(overview.totalPercValue) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-expand-transition>
      <div v-show="areDetailsExpanded">
        <holding-loans-table
          dense
          fixed-header
          :mobile-breakpoint="0"

          :items="filteredItems"

          must-sort
          sort-by="accruedFees"
          sort-desc

          :items-per-page="-1"
          hide-default-footer
        />
      </div>
    </v-expand-transition>

    <v-dialog v-model="isFiltersDialogOpen" max-width="400">
      <v-card>
        <v-card-title>
          Filtros
        </v-card-title>

        <v-container fluid class="pt-0">
          <v-row>
            <v-col cols="12">
              <filters-autocomplete
                label="Ativo"
                hide-details
                show-select-all

                multiple
                clearable
                small-chips
                deletable-chips

                :items="filterOptions.ticker"
                v-model="filters.ticker"
              />
            </v-col>

            <v-col cols="12">
              <filters-autocomplete
                label="Book"
                hide-details
                show-select-all

                multiple
                clearable
                small-chips
                deletable-chips

                :items="filterOptions.book"
                v-model="filters.book"
              />
            </v-col>

            <v-col cols="12">
              <filters-autocomplete
                label="Ponta"
                hide-details
                show-select-all

                multiple
                clearable
                small-chips
                deletable-chips

                :items="filterOptions.side"
                v-model="filters.side"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn small color="primary" @click="isFiltersDialogOpen = false">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { sumBy, uniq } from 'lodash';

import {
  formatMoney,
  formatNumber,
  formatPercentage,
  formatPerformance,
} from '@/utils/format-utils';

import HoldingLoansTable from './HoldingLoansTable.vue';
import FiltersAutocomplete from './FiltersAutocomplete.vue';

export default {
  name: 'HoldingLoans',

  components: {
    HoldingLoansTable,
    FiltersAutocomplete,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    areDetailsExpanded: false,
    isFiltersDialogOpen: false,

    filters: {
      ticker: [],
      book: [],
      side: [],
    },
  }),

  computed: {
    filterCount: (vm) => Object.values(vm.filters).filter((val) => (Array.isArray(val) ? val.length > 0 : !!val)).length,

    filterOptions: (vm) => ({
      ticker: uniq(vm.items.map((item) => item.ticker)).sort(),
      book: uniq(vm.items.map((item) => item.book)).sort(),
      side: uniq(vm.items.map((item) => item.side)).sort(),
    }),

    filteredItems: (vm) => vm.items.filter((item) => {
      const rules = [
        !vm.filters.ticker.length || vm.filters.ticker.includes(item.ticker),
        !vm.filters.book.length || vm.filters.book.includes(item.book),
        !vm.filters.side.length || vm.filters.side.includes(item.side),
      ];

      return rules.every(Boolean);
    }),

    overview: (vm) => ({
      totalNetQuantity: sumBy(vm.filteredItems, 'quantity'),
      totalGrossQuantity: sumBy(vm.filteredItems, (item) => Math.abs(item.quantity ?? 0)),
      totalAccruedFees: sumBy(vm.filteredItems, 'accruedFees'),
      // totalMtdPerf: sumBy(vm.filteredItems, 'mtdPerf'),
      // totalYtdPerf: sumBy(vm.filteredItems, 'ytdPerf'),
      totalPercValue: sumBy(vm.filteredItems, 'percValue'),
    }),
  },

  methods: {
    formatMoney,
    formatNumber,
    formatPercentage,
    formatPerformance,
  },
};
</script>

<style lang="scss" scoped>
.overview-container {
  background: #eeee;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}
</style>
