<template>
  <v-card width="480px">
    <v-card-subtitle class="tooltip-header">
      {{ data.book }}
    </v-card-subtitle>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Classe</th>
              <th>Exposição</th>
              <th>% Book</th>
              <th>% PL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="data.fundsHoldings">
              <td>Fundos</td>
              <td>{{ formatMoney(data.fundsHoldings) }}</td>
              <td>{{ formatPercentage(calcBookRatio(data.fundsHoldings)) }}</td>
              <td>{{ formatPercentage(calcNetValueRatio(data.fundsHoldings)) }}</td>
            </tr>

            <tr v-if="data.sharesHoldings">
              <td>Ações</td>
              <td>{{ formatMoney(data.sharesHoldings) }}</td>
              <td>{{ formatPercentage(calcBookRatio(data.sharesHoldings)) }}</td>
              <td>{{ formatPercentage(calcNetValueRatio(data.sharesHoldings)) }}</td>
            </tr>

            <tr v-if="data.shareOptionsHoldings">
              <td>Opções de Ações</td>
              <td>{{ formatMoney(data.shareOptionsHoldings) }}</td>
              <td>{{ formatPercentage(calcBookRatio(data.shareOptionsHoldings)) }}</td>
              <td>{{ formatPercentage(calcNetValueRatio(data.shareOptionsHoldings)) }}</td>
            </tr>

            <tr v-if="data.bondsHoldings">
              <td>Renda Fixa</td>
              <td>{{ formatMoney(data.bondsHoldings) }}</td>
              <td>{{ formatPercentage(calcBookRatio(data.bondsHoldings)) }}</td>
              <td>{{ formatPercentage(calcNetValueRatio(data.bondsHoldings)) }}</td>
            </tr>

            <tr v-if="data.futuresHoldings">
              <td>Futuros</td>
              <td>{{ formatMoney(data.futuresHoldings) }}</td>
              <td>{{ formatPercentage(calcBookRatio(data.futuresHoldings)) }}</td>
              <td>{{ formatPercentage(calcNetValueRatio(data.futuresHoldings)) }}</td>
            </tr>

            <tr v-if="data.futureOptionsHoldings">
              <td>Opções de Futuros</td>
              <td>{{ formatMoney(data.futureOptionsHoldings) }}</td>
              <td>{{ formatPercentage(calcBookRatio(data.futureOptionsHoldings)) }}</td>
              <td>{{ formatPercentage(calcNetValueRatio(data.futureOptionsHoldings)) }}</td>
            </tr>

            <tr v-if="data.repurchaseHoldings">
              <td>Compromissadas</td>
              <td>{{ formatMoney(data.repurchaseHoldings) }}</td>
              <td>{{ formatPercentage(calcBookRatio(data.repurchaseHoldings)) }}</td>
              <td>{{ formatPercentage(calcNetValueRatio(data.repurchaseHoldings)) }}</td>
            </tr>

            <tr class="totalization-row">
              <td>Total</td>
              <td>{{ formatMoney(data.value) }}</td>
              <td>{{ formatPercentage(1) }}</td>
              <td>{{ formatPercentage(data.percentValue) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMoney, formatPercentage } from '@/utils/format-utils';
import Decimal from 'decimal.js';

export default {
  name: 'HoldingExposureChartTooltip',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    netValue: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    formatMoney,
    formatPercentage,

    calcBookRatio(classValue) {
      return Decimal(classValue).div(this.data.value);
    },
    calcNetValueRatio(classValue) {
      return Decimal(classValue).div(this.netValue);
    },
  },
};
</script>

<style scoped lang="scss">
td, th {
  text-align: end !important;
}
.tooltip-header {
  text-align: center;
  background-color: #18324f;
  color: white !important;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 10px;
  border: none !important;
}
.totalization-row {
  background-color: #eee;
  font-weight: bold;
  color: #18324f;
}
</style>
