<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    item-key="_id"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.name="{ item }">
      <router-link class="link" :to="`/funds/${item.fundId}`" target="_blank">
        <v-tooltip bottom content-class="grey darken-4">
          <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
            <div
              v-on="tooltipEvents"
              v-bind="tooltipAttrs"
              class="text-truncate"
              style="max-width: 220px"
            >
              {{ item.name }}
            </div>
          </template>

          <template v-slot:default>
            {{ item.name }}
          </template>
        </v-tooltip>
      </router-link>
    </template>

    <template v-slot:item.quantity="{ item }">
      <span :class="{ 'error--text': item.quantity < 0 }">
        {{ formatNumber(item.quantity) }}
      </span>
    </template>

    <template v-slot:item.unitaryPrice="{ item }">
      {{ formatNumber(item.unitaryPrice) }}
    </template>

    <template v-slot:item.value="{ item }">
      <span :class="{ 'error--text': item.value < 0 }">
        {{ formatMoney(item.value) }}
      </span>
    </template>

    <template v-slot:item.mtdPerf="{ item }">
      <span :class="{ 'error--text': item.mtdPerf < 0 }">
        {{ formatPerformance(item.mtdPerf) }}
      </span>
    </template>

    <template v-slot:item.ytdPerf="{ item }">
      <span :class="{ 'error--text': item.ytdPerf < 0 }">
        {{ formatPerformance(item.ytdPerf) }}
      </span>
    </template>

    <template v-slot:item.mtd="{ item }">
      <span :class="{ 'error--text': item.mtd < 0 }">
        {{ formatPerformance(item.mtd) }}
      </span>
    </template>

    <template v-slot:item.ytd="{ item }">
      <span :class="{ 'error--text': item.ytd < 0 }">
        {{ formatPerformance(item.ytd) }}
      </span>
    </template>

    <template v-slot:item.percValue="{ item }">
      <span :class="{ 'error--text': item.percValue < 0 }">
        {{ formatPercentage(item.percValue) }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  formatMoney,
  formatNumber,
  formatPercentage,
  formatPerformance,
} from '@/utils/format-utils';

import copyToClipboard from '@/utils/copy-to-clipboard';

export default {
  name: 'HoldingFundsTable',

  inheritAttrs: false,

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '48px',
      },

      {
        value: 'name',
        text: 'Fundo',
      },

      {
        value: 'serial',
        text: 'CNPJ',
      },

      {
        value: 'book',
        text: 'Book',
      },

      {
        value: 'quantity',
        text: 'Qtd. de Cotas',
        align: 'end',
      },

      {
        value: 'unitaryPrice',
        text: 'Cota',
        align: 'end',
      },

      {
        value: 'value',
        text: 'Valor',
        align: 'end',
      },

      {
        value: 'mtdPerf',
        text: 'Atrib. Perf. Mensal',
        align: 'end',
      },

      {
        value: 'ytdPerf',
        text: 'Atrib. Perf. Anual',
        align: 'end',
      },

      {
        value: 'mtd',
        text: 'MTD',
        align: 'end',
      },

      {
        value: 'ytd',
        text: 'YTD',
        align: 'end',
      },

      {
        value: 'percValue',
        text: '%PL',
        align: 'end',
      },
    ],
  }),

  methods: {
    formatMoney,
    formatNumber,
    formatPercentage,
    formatPerformance,

    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  table {
    th,
    td {
      &:nth-child(2):not(:only-child) {
        position: sticky !important;
        z-index: 3 !important;
        left: 0 !important;

        background-color: white;
      }
    }
    tr:hover td {
      background-color: inherit !important;
    }
  }
}

.link {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
