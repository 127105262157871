var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipProps){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.copyId(item._id); }}},'v-btn',tooltipProps.attrs,false),tooltipProps.on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Copiar _id ")]},proxy:true}],null,true)})]}},{key:"item.optionType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.optionType === 'PUT' ? 'Venda' : 'Call')+" ")]}},{key:"item.strike",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.strike))+" ")]}},{key:"item.expirationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expirationDate))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.quantity < 0 }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.quantity))+" ")])]}},{key:"item.refPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.refPrice))+" ")]}},{key:"item.totalDeltaExposure",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.totalDeltaExposure < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.totalDeltaExposure))+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.value < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.value))+" ")])]}},{key:"item.mtdPerf",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.mtdPerf < 0 }},[_vm._v(" "+_vm._s(_vm.formatPerformance(item.mtdPerf))+" ")])]}},{key:"item.ytdPerf",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.ytdPerf < 0 }},[_vm._v(" "+_vm._s(_vm.formatPerformance(item.ytdPerf))+" ")])]}},{key:"item.percValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.percValue < 0 }},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.percValue))+" ")])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }