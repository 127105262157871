<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    item-key="_id"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.description="{ item }">
      {{ item.description }} {{ currencyType(item) }}
    </template>

    <template v-slot:item.releaseDate="{ item }">
      {{ formatDate(item.releaseDate) }}
    </template>

    <template v-slot:item.settlementDate="{ item }">
      {{ formatDate(item.settlementDate) }}
    </template>

    <template v-slot:item.pnRType="{ item }">
      {{ formatPnrType(item.pnRType) }}
    </template>

    <template v-slot:item.value="{ item }">
      <span :class="{ 'error--text': item.value < 0 }">
        {{ formatMoney(item.value) }}
      </span>
    </template>

    <!-- <template v-slot:item.mtdPerf="{ item }">
      <span :class="{ 'error--text': item.mtdPerf < 0 }">
        {{ formatPerformance(item.mtdPerf) }}
      </span>
    </template>

    <template v-slot:item.ytdPerf="{ item }">
      <span :class="{ 'error--text': item.ytdPerf < 0 }">
        {{ formatPerformance(item.ytdPerf) }}
      </span>
    </template> -->

    <template v-slot:item.percValue="{ item }">
      <span :class="{ 'error--text': item.percValue < 0 }">
        {{ formatPercentage(item.percValue) }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  formatMoney,
  formatDate,
  formatNumber,
  formatPercentage,
  formatPerformance,
} from '@/utils/format-utils';

import copyToClipboard from '@/utils/copy-to-clipboard';

export default {
  name: 'HoldingPnrTable',

  inheritAttrs: false,

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '48px',
      },

      {
        value: 'description',
        text: 'Descrição',
      },

      {
        value: 'origin',
        text: 'Origem',
      },

      {
        value: 'pnRType',
        text: 'Tipo',
      },

      {
        value: 'releaseDate',
        text: 'Data de referência',
      },

      {
        value: 'settlementDate',
        text: 'Vencimento',
      },

      {
        value: 'value',
        text: 'Valor',
        align: 'end',
      },

      // {
      //   value: 'mtdPerf',
      //   text: 'Atrib. Perf. Mensal',
      //   align: 'end',
      // },

      // {
      //   value: 'ytdPerf',
      //   text: 'Atrib. Perf. Anual',
      //   align: 'end',
      // },

      {
        value: 'percValue',
        text: '%PL',
        align: 'end',
      },
    ],
  }),
  methods: {
    currencyType: (item) => (item.value === item.currencyValue ? '' : ` (${formatMoney(item.currencyValue)} ${item.currency})`),
    formatDate,
    formatMoney,
    formatNumber,
    formatPercentage,
    formatPerformance,

    formatPnrType(type) {
      switch (type) {
        case 'FIXED_SETTLEMENT': return 'Fixo';
        case 'PROVISION': return 'Provisão';
        default: return type;
      }
    },

    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>
