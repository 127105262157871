export function parseRequestFilters(rawFilters: Record<string, any>) {
  const houseFundIds = rawFilters.houseFundIds ?? [];

  const initialDate = rawFilters.initialDate ?? '1970-01-01';
  const finalDate = rawFilters.finalDate ?? '2999-01-01';

  return {
    houseFunds: houseFundIds.length > 0 ? houseFundIds.join(',') : null,
    refDate: `${initialDate}:${finalDate}`,
  };
}

export function getApiErrors(error: Record<string, any>): string[] {
  if (error.response) {
    const serverError = error.response.data.error?.error ?? error.response.data.error ?? error.response.data;
    return serverError.meta?.errorList ?? serverError.meta?.errorsList ?? [serverError.message ?? error.message];
  }

  return [error.message];
}
