<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" :value="value" :max-width="maxWidth">
    <v-card>
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>

      <v-form v-model="isFormValid" @submit.prevent="() => submit()">
        <v-container fluid class="px-6">
          <v-row dense>
            <v-col cols="12" sm="6">
              <date-picker-field
                label="Data inicial"
                :picker-props="{ allowedDates, max: formFields.finalDate }"

                :value="formFields.initialDate || defaultFormFields.initialDate"
                @input="formFields.initialDate = $event"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker-field
                label="Data final"
                :picker-props="{ allowedDates, min: formFields.initialDate }"

                :value="formFields.finalDate || defaultFormFields.finalDate"
                @input="formFields.finalDate = $event"
              />
            </v-col>

            <v-col cols="12">
              <house-funds-selector
                label="Fundos"
                hint="Campo obrigatório"
                persistent-hint

                item-text="shortName"
                item-value="_id"

                multiple
                clearable
                show-select-all
                ellipsed-selection

                :value="formFields.houseFundIds.length ? formFields.houseFundIds : defaultFormFields.houseFundIds"
                @input="formFields.houseFundIds = $event"
                :rules="[vRules.requiredField]"
              />
            </v-col>
          </v-row>

          <v-row dense justify="end">
            <v-col cols="12" sm="auto">
              <v-btn text block color="error" @click="() => $emit('input', false)">
                Cancelar
              </v-btn>
            </v-col>

            <v-col cols="12" sm="auto">
              <v-btn block color="primary" type="submit" :disabled="!isFormValid">
                {{ submitBtnText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-loyall';

import { requiredField } from '@/utils/validators';

import DatePickerField from '@/components/global/DatePickerField.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';

export default {
  name: 'HoldingPeriodPickerDialog',

  inheritAttrs: false,

  components: {
    DatePickerField,
    HouseFundsSelector,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: [String, Number],
      default: '500',
    },

    title: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },

    defaultFormFields: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isFormValid: false,
    allowedDates: (date) => moment(date).isBusinessDay('brasil'),

    formFields: {
      houseFundIds: [],
      initialDate: null,
      finalDate: null,
    },

    vRules: {
      requiredField,
    },
  }),

  methods: {
    submit() {
      this.$emit('submit', {
        houseFundIds: this.formFields.houseFundIds.length
          ? this.formFields.houseFundIds
          : this.defaultFormFields.houseFundIds,
        finalDate: this.formFields.finalDate || this.defaultFormFields.finalDate,
        initialDate: this.formFields.initialDate || this.defaultFormFields.initialDate,
      });
    },
  },
};
</script>
