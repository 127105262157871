<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    item-key="_id"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip right content-class="grey darken-4">
        <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
          <v-btn
            small
            icon
            color="primary"
            class="mx-1"
            :to="`/holdings/${item.houseFundId}?refDate=${item.refDate.substring(0, 10)}`"
            v-bind="tooltipAttrs"
            v-on="tooltipEvents"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Ver detalhes
        </template>
      </v-tooltip>

      <v-tooltip right content-class="grey darken-4">
        <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
          <v-btn
            small
            icon
            color="indigo"
            class="mx-1"
            :disabled="readonly"
            @click="$emit('click:calculate', item)"
            v-bind="tooltipAttrs"
            v-on="tooltipEvents"
          >
            <v-icon>
              mdi-calculator-variant-outline
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Calcular carteira
        </template>
      </v-tooltip>

      <v-tooltip right content-class="grey darken-4">
        <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
          <v-btn
            small
            icon
            class="mx-1"
            :color="item.hasOfficialHolding ? 'info' : 'error'"
            :disabled="readonly"
            @click="$emit(item.hasOfficialHolding ? 'click:analyze' : 'click:upload', item)"
            v-bind="tooltipAttrs"
            v-on="tooltipEvents"
          >
            <v-icon>
              {{ item.hasOfficialHolding ? 'mdi-clipboard-pulse-outline' : 'mdi-clipboard-alert-outline' }}
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          {{ item.hasOfficialHolding ? 'Validar posição' : 'O XML oficial ainda não foi importado' }}
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.refDate="{ item }">
      {{ formatDate(item.refDate) }}
    </template>

    <template v-slot:item.netValue="{ item }">
      {{ formatMoney(item.netValue || item.calculatedNetValue) }}
    </template>

    <template v-slot:item.price="{ item }">
      {{ formatQuota(item.price || item.calculatedPrice) }}
    </template>

    <template v-slot:item.shareQuantity="{ item }">
      <span :class="{ 'error--text': item.shareQuantity < 0 }">
        {{ formatNumber(item.shareQuantity) }}
      </span>
    </template>

    <template v-slot:item.dailyReturn="{ item }">
      <span :class="{ 'error--text': item.dailyReturn < 0 }">
        {{ formatReturn(item.dailyReturn) }}
      </span>
    </template>

    <template v-slot:item.status="{ item }">
      <holdings-status-indicator :status="item.status" />
    </template>
  </v-data-table>
</template>

<script>
import {
  formatDate,
  formatMoney,
  formatNumber,
  formatPercentage,
} from '@/utils/format-utils';

import HoldingsStatusIndicator from './HoldingsStatusIndicator.vue';

export default {
  name: 'HoldingsTable',

  components: {
    HoldingsStatusIndicator,
  },

  inheritAttrs: false,

  props: {
    columns: {
      type: Array,
      default: () => [],
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rawHeaders: [
      {
        text: 'Ações',
        value: 'actions',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '154px',
      },

      {
        text: 'Fundo',
        value: 'shortName',
      },

      {
        text: 'Data',
        value: 'refDate',
      },

      {
        text: 'Patrimônio Líquido',
        value: 'netValue',
        align: 'end',
      },

      {
        text: 'Cota Líquida',
        value: 'price',
        align: 'end',
      },

      {
        text: 'Qtd. de cotas',
        value: 'shareQuantity',
        align: 'end',
      },

      {
        text: 'Retorno diário',
        value: 'dailyReturn',
        align: 'end',
      },

      {
        text: 'Status',
        value: 'status',
      },
    ],
  }),

  computed: {
    headers: (vm) => (vm.columns.length > 0
      ? vm.rawHeaders.filter((item) => vm.columns.includes(item.value))
      : vm.rawHeaders
    ),
  },

  methods: {
    formatDate,
    formatMoney,
    formatNumber,
    formatQuota: (val) => formatNumber(val, { minimumFractionDigits: 8 }),
    formatReturn: (val) => formatPercentage(val, { maximumFractionDigits: 2 }),
  },
};
</script>
