<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    item-key="_id"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.annualFee="{ item }">
      {{ formatPercentage(item.annualFee) }}
    </template>

    <template v-slot:item.operationDate="{ item }">
      {{ formatDate(item.operationDate) }}
    </template>

    <template v-slot:item.loanDueDate="{ item }">
      {{ formatDate(item.loanDueDate) }}
    </template>

    <template v-slot:item.quantity="{ item }">
      <span :class="{ 'error--text': item.quantity < 0 }">
        {{ formatNumber(item.quantity) }}
      </span>
    </template>

    <template v-slot:item.refPrice="{ item }">
      <span :class="{ 'error--text': item.refPrice < 0 }">
        {{ formatMoney(item.refPrice) }}
      </span>
    </template>

    <template v-slot:item.accruedFees="{ item }">
      <span :class="{ 'error--text': item.accruedFees < 0 }">
        {{ formatMoney(item.accruedFees) }}
      </span>
    </template>

    <!-- <template v-slot:item.mtdPerf="{ item }">
      <span :class="{ 'error--text': item.mtdPerf < 0 }">
        {{ formatPerformance(item.mtdPerf) }}
      </span>
    </template>

    <template v-slot:item.ytdPerf="{ item }">
      <span :class="{ 'error--text': item.ytdPerf < 0 }">
        {{ formatPerformance(item.ytdPerf) }}
      </span>
    </template> -->

    <template v-slot:item.percValue="{ item }">
      <span :class="{ 'error--text': item.percValue < 0 }">
        {{ formatPercentage(item.percValue) }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  formatMoney,
  formatDate,
  formatNumber,
  formatPercentage,
  formatPerformance,
} from '@/utils/format-utils';

import copyToClipboard from '@/utils/copy-to-clipboard';

export default {
  name: 'HoldingSharesTable',

  inheritAttrs: false,

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '48px',
      },

      {
        value: 'ticker',
        text: 'Ativo',
      },

      {
        value: 'book',
        text: 'Book',
      },

      {
        value: 'side',
        text: 'Ponta',
      },

      {
        value: 'annualFee',
        text: 'Taxa',
        align: 'end',
      },

      {
        value: 'operationDate',
        text: 'Data operação',
      },

      {
        value: 'loanDueDate',
        text: 'Vencimento',
      },

      {
        value: 'quantity',
        text: 'Quantidade',
        align: 'end',
      },

      {
        value: 'refPrice',
        text: 'PU',
        align: 'end',
      },

      {
        value: 'accruedFees',
        text: 'Taxa acruada',
        align: 'end',
      },

      // {
      //   value: 'mtdPerf',
      //   text: 'Atrib. Perf. Mensal',
      //   align: 'end',
      // },

      // {
      //   value: 'ytdPerf',
      //   text: 'Atrib. Perf. Anual',
      //   align: 'end',
      // },

      {
        value: 'percValue',
        text: '%PL',
        align: 'end',
      },
    ],
  }),

  methods: {
    formatMoney,
    formatDate,
    formatNumber,
    formatPercentage,
    formatPerformance,

    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>
