<template>
  <v-chip
    v-if="type === 'chip'"
    v-bind="$attrs"
    v-on="$listeners"
    :color="statusColor"
  >
    <v-avatar left v-if="statusIcon">
      <v-icon :small="$attrs.small">
        {{ statusIcon }}
      </v-icon>
    </v-avatar>

    {{ statusText }}
  </v-chip>

  <span
    :class="statusColor ? `${statusColor}--text` : ''"
    v-bind="$attrs"
    v-on="$listeners"
    v-else
  >
    {{ statusText }}
  </span>
</template>

<script>
export default {
  name: 'HoldingsStatusIndicator',

  inheritAttrs: false,

  props: {
    status: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'span',
    },
  },

  computed: {
    statusColor: (vm) => {
      switch (vm.status) {
        case 'APPROVED': return 'success';
        case 'APPROVED_WITH_WARNINGS': return 'success';
        case 'REPROVED': return 'error';
        case 'PARTIALLY_CALCULATED': return 'warning';
        default: return '';
      }
    },

    statusText: (vm) => {
      switch (vm.status) {
        case 'INSERTED': return 'Inserido';
        case 'CREATED': return 'Criado';
        case 'PARTIALLY_CALCULATED': return 'Parcialmente calculado';
        case 'CALCULATED': return 'Calculado';
        case 'APPROVED': return 'Aprovado';
        case 'APPROVED_WITH_WARNINGS': return 'Aprovado com ressalvas';
        case 'REPROVED': return 'Reprovado';
        default: return vm.status;
      }
    },

    statusIcon: (vm) => {
      switch (vm.status) {
        case 'PARTIALLY_CALCULATED': return 'mdi-alert-outline';
        case 'APPROVED_WITH_WARNINGS': return 'mdi-alert-outline';
        case 'APPROVED': return 'mdi-check';
        case 'REPROVED': return 'mdi-alert-circle-outline';
        default: return '';
      }
    },
  },
};
</script>
