<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" :value="value">
    <v-card tile>
      <v-card-title>
        Importar XML Oficial Anbima
      </v-card-title>

      <v-card-subtitle>
        Clique no campo abaixo ou arraste e solte os arquivos nele para adicioná-los
      </v-card-subtitle>

      <v-card-text>
        <div @dragover.prevent @drop.prevent="handleDragAndDrop">
          <v-file-input
            type="file"
            v-model="files"
            accept=".xml"
            prepend-icon="mdi-file-upload"
            label="Arquivos de posição"
            hint="Somente XML 5.0 (preferível) ou XML 4.01"
            truncate-length="50"
            :rules="rules.fieldMaxFiles"

            multiple
            counter
            show-size
            persistent-hint
            outlined
          >
            <template v-slot:selection="item">
              <v-chip small close  @click:close="removeFile(item)">
                {{ item.text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn text color="error" @click="() => $emit('input', false)">
          Cancelar
        </v-btn>

        <v-btn color="primary" :disabled="!canSubmitFiles || loading" @click="() => submitFiles()">
          Importar
        </v-btn>
      </v-card-actions>

      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate size="48" />
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { find } from 'lodash';

export default {
  name: 'XmlUploadDialog',

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: (vm) => ({
    files: [],

    bulkImportMaxSize: 11,

    rules: {
      fieldMaxFiles: [
        () => vm.files.length <= vm.bulkImportMaxSize
          || `No máximo até ${vm.bulkImportMaxSize} arquivo(s)`,
      ],
    },
  }),

  computed: {
    canSubmitFiles: (vm) => vm.files.length && vm.files.length <= vm.bulkImportMaxSize,
  },

  methods: {
    handleDragAndDrop(event) {
      const isXml = (file) => file.type === 'text/xml';
      const isUnique = (file) => !find(this.files, {
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
      });

      this.files.push(...Array
        .from(event.dataTransfer.files)
        .filter((el) => isXml(el) && isUnique(el)));
    },

    removeFile(file) {
      this.files.splice(file.index, 1);
    },

    submitFiles() {
      this.$emit('submit', this.files);
      this.$emit('close');

      this.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-file-input__text, fieldset {
    min-height: 100px;
    background-color: rgb(240, 240, 240);
  }
  fieldset {
    border: 2px dashed rgb(230, 230, 230);
  }
}
</style>
