<template>
  <v-container fluid class="holdings-details">
    <h1 class="text-h5">Detalhes da posição</h1>

    <v-container fluid>
      <v-row>
        <v-col>
          <house-funds-selector
            label="Fundo"
            hide-details
            dense

            item-text="shortName"
            item-value="_id"
            :value="houseFundId"
            @change="(val) => changeHouseFundId(val)"
          />
        </v-col>

        <v-col>
          <date-picker-field
            label="Data"
            hide-details
            dense

            v-model="refDate"
            :picker-props="{ allowedDates: getAllowedDates }"

            prepend-icon="mdi-arrow-left"
            @click:prepend="() => prevDate()"

            append-outer-icon="mdi-arrow-right"
            @click:append-outer="() => nextDate()"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-progress-linear
      top
      absolute
      indeterminate
      color="#9d6c32"
      :active="isFetchingData"
    />

    <template v-if="hasFoundData">
      <div class="text-right">
        <v-btn
          small
          rounded
          color="primary"
          class="mx-1"
          @click="() => fetchData()"
        >
          <v-icon small left>
            mdi-refresh
          </v-icon>

          Atualizar
        </v-btn>

        <v-btn
          small
          rounded
          color="primary"
          class="mx-2"
          @click="() => calculatePortfolio()"
          v-if="isElevatedUser"
        >
          <v-icon small left>
            mdi-calculator-variant-outline
          </v-icon>

          Calcular
        </v-btn>
        <v-btn
          small
          rounded
          color="primary"
          class="mx-2"
          :disabled="!details.hasOfficialHolding"
          @click="() => analyzeHolding()"
          v-if="isElevatedUser"
        >
          <v-icon small left>
            mdi-clipboard-pulse-outline
          </v-icon>

          Comparar
        </v-btn>

        <v-btn
          small
          rounded
          color="primary"
          class="mx-2"
          @click="() => requeue()"
          v-if="isElevatedUser"
          :loading="isRequeueLoading"
          >
          <v-icon small left>
            mdi-recycle-variant
          </v-icon>
          Reciclar XML
        </v-btn>
      </div>

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <holding-general-info
              :details="details"
              :riskData="riskData || {}"
            />
          </v-col>

          <v-col cols="12" v-if="filteredBooks.length">
            <holding-exposure
              :items="filteredBooks"
              :netValue="details.netValue || details.calculatedNetValue"
            />
          </v-col>

          <v-col cols="12" v-if="details.sharesHoldings.length">
            <holding-shares :items="details.sharesHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.shareOptionsHoldings.length">
            <holding-share-options :items="details.shareOptionsHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.loanHoldings.length">
            <holding-loans :items="details.loanHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.futuresHoldings.length">
            <holding-futures :items="details.futuresHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.futureOptionsHoldings.length">
            <holding-future-options :items="details.futureOptionsHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.fundsHoldings.length">
            <holding-funds :items="details.fundsHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.bondsHoldings.length">
            <holding-bonds :items="details.bondsHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.repurchaseHoldings.length">
            <holding-repurchase :items="details.repurchaseHoldings" />
          </v-col>

          <v-col cols="12" v-if="details.pnRHoldings.length">
            <holding-pnr :items="details.pnRHoldings" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-else-if="hasRequestedData">
      <empty-state
        icon="mdi-calendar-remove"
        title="Posição não encontrada"
        message="Não há posições de carteira para o dia e fundo em questão."
      >
        <v-btn color="primary" @click="() => calculatePortfolio()">
          <v-icon small left>
            mdi-calculator-variant-outline
          </v-icon>

          Calcular
        </v-btn>
      </empty-state>
    </template>

    <holding-calculation-feedback
      v-model="isCalculationFeedbackDialogOpen"
      :loading="isCalculatingHoldings"
      :error-list="calculatingErrors"
    />

    <holding-analysis-feedback
      v-model="isAnalysisFeedbackDialogOpen"
      :loading="isAnalyzingHoldings"
      :status="analysisStatus"
      :error-list="analyzingErrors"
    >
      <template v-slot:actions-error>
        <v-card-actions class="justify-center pb-4">
          <v-btn
            outlined
            color="primary"
            :disabled="isApprovingHoldings"
            :loading="isApprovingHoldings"
            @click="() => approveHoldings()"
          >
            Aprovar com ressalvas
          </v-btn>

          <v-btn
            color="primary"
            :disabled="isApprovingHoldings"
            @click="isAnalysisFeedbackDialogOpen = false"
          >
            Aceitar
          </v-btn>
        </v-card-actions>
      </template>

      <template v-slot:actions-approved_with_warnings>
        <v-card-actions class="justify-center pb-4">
          <v-btn
            outlined
            color="error"
            :disabled="isApprovingHoldings"
            :loading="isApprovingHoldings"
            @click="() => reproveHoldings()"
          >
            Descartar ressalvas
          </v-btn>

          <v-btn
            color="primary"
            :disabled="isApprovingHoldings"
            @click="isAnalysisFeedbackDialogOpen = false"
          >
            Aceitar
          </v-btn>
        </v-card-actions>
      </template>
    </holding-analysis-feedback>

    <contextual-dialog
      v-model="isErrorDialogOpen"
      icon="mdi-exclamation-thick"
      icon-color="error"
      :title="errorTitle"
      :message="errorMessage"
    >
      <template v-slot:default v-if="errorList.length > 0">
        <v-divider class="mx-4 mb-4" />

        <v-card-text class="text-left">
          <template v-for="(error, index) of errorList">
            <p :key="index" class="text-body-2 mb-0 mt-4">
              <v-icon color="error" left>
                mdi-alert-circle-outline
              </v-icon>

              <span style="vertical-align: middle">
                {{ error }}
              </span>
            </p>
          </template>
        </v-card-text>
      </template>
    </contextual-dialog>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';
import { orderBy } from 'lodash';
import api from '@/services/api';
/* eslint-disable-next-line */
import { loadValueFromUrl, saveValueOnUrl } from '@/utils/router-utils';

import ContextualDialog from '@/components/global/ContextualDialog.vue';
import DatePickerField from '@/components/global/DatePickerField.vue';
import EmptyState from '@/components/global/EmptyState.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';

import { getApiErrors } from './utils/request-utils';

import HoldingGeneralInfo from './HoldingGeneralInfo.vue';
import HoldingShares from './HoldingShares.vue';
import HoldingExposure from './HoldingExposure.vue';
import HoldingShareOptions from './HoldingShareOptions.vue';
import HoldingLoans from './HoldingLoans.vue';
import HoldingFutures from './HoldingFutures.vue';
import HoldingFutureOptions from './HoldingFutureOptions.vue';
import HoldingFunds from './HoldingFunds.vue';
import HoldingBonds from './HoldingBonds.vue';
import HoldingRepurchase from './HoldingRepurchase.vue';
import HoldingPnr from './HoldingPnr.vue';
import HoldingCalculationFeedback from './HoldingCalculationFeedback.vue';
import HoldingAnalysisFeedback from './HoldingAnalysisFeedback.vue';

export default {
  name: 'HoldingsDetailsView',

  components: {
    ContextualDialog,
    DatePickerField,
    EmptyState,
    HouseFundsSelector,

    HoldingGeneralInfo,
    HoldingExposure,
    HoldingShares,
    HoldingShareOptions,
    HoldingLoans,
    HoldingFutures,
    HoldingFutureOptions,
    HoldingFunds,
    HoldingBonds,
    HoldingRepurchase,
    HoldingPnr,
    HoldingCalculationFeedback,
    HoldingAnalysisFeedback,
  },

  props: {
    houseFundId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    refDate: loadValueFromUrl('refDate') ?? '',

    details: null,
    riskData: null,
    isFetchingData: false,
    hasRequestedData: false,

    isErrorDialogOpen: false,
    errorTitle: '',
    errorMessage: '',
    errorList: [],

    isCalculationFeedbackDialogOpen: false,
    isCalculatingHoldings: false,
    calculatingErrors: [],

    isAnalysisFeedbackDialogOpen: false,
    isAnalyzingHoldings: false,
    analysisStatus: 'IDLE',
    analyzingErrors: [],

    isApprovingHoldings: false,

    isRequeueFeedbackDialogOpen: false,
    isRequeueLoading: false,
  }),

  computed: {
    isElevatedUser: (vm) => ['admin', 'backoffice'].includes(vm.$store.state.user?.acl.toLowerCase()),
    hasFoundData: (vm) => Object.keys(vm.details ?? {}).length > 0,
    filteredBooks() {
      const BLOCKED_BOOKS = ['CAIXA', 'PNR'];
      const items = this.details?.books.filter((item) => !BLOCKED_BOOKS.includes(item.book)) ?? [];
      return orderBy(items, 'value', 'desc');
    },
  },

  watch: {
    refDate(newValue) {
      saveValueOnUrl('refDate', newValue);

      this.details = null;
      this.hasRequestedData = false;
      this.fetchData();
    },

    houseFundId() {
      this.details = null;
      this.hasRequestedData = false;
      this.fetchData();
    },
  },

  created() {
    this.$store.dispatch('houseFunds/fetchList');
    this.fetchData();
  },

  methods: {
    async requeue() {
      this.isRequeueLoading = true;
      try {
        const { data } = await api.operator.requeueHolding(this.houseFundId, this.refDate);
        console.log(data);
      } catch (error) {
        this.errorTitle = 'Algo de errado aconteceu!';
        this.errorMessage = 'Não foi possível reciclar o XML.';
      } finally {
        this.isRequeueLoading = false;
      }
    },

    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    prevDate() {
      const targetDate = moment(this.refDate).prevBusinessDay('brasil');
      this.refDate = targetDate.format('YYYY-MM-DD');
    },

    nextDate() {
      const targetDate = moment(this.refDate).nextBusinessDay('brasil');
      this.refDate = targetDate.format('YYYY-MM-DD');
    },

    changeHouseFundId(newId) {
      const { hash, query } = this.$router.currentRoute;
      this.$router.replace({ path: `/holdings/${newId}`, hash, query });
    },

    async fetchData() {
      this.isFetchingData = true;

      try {
        this.fetchRiskData();

        const { data } = await api.holdings.getHoldingsDetails(this.houseFundId, this.refDate);
        this.details = data;

        if (data && !this.refDate) {
          this.refDate = data.refDate?.substring(0, 10);
        }
      } catch (error) {
        const errorList = getApiErrors(error);

        this.errorTitle = 'Algo de errado aconteceu!';
        this.errorMessage = 'Um erro inesperado ocorreu, tente novamente mais tarde.';
        this.errorList = errorList;
        this.isErrorDialogOpen = true;
      } finally {
        this.hasRequestedData = true;
        this.isFetchingData = false;
      }
    },

    async calculatePortfolio() {
      this.isCalculatingHoldings = true;
      this.calculatingErrors = [];
      this.isCalculationFeedbackDialogOpen = true;

      try {
        await api.holdings.calculatePortfolio(this.houseFundId, this.refDate);
      } catch (error) {
        this.calculatingErrors = getApiErrors(error);
      } finally {
        this.isCalculatingHoldings = false;
        this.fetchData();
      }
    },

    async analyzeHolding() {
      this.isAnalyzingHoldings = true;
      this.analyzingErrors = [];
      this.analysisStatus = 'LOADING';
      this.isAnalysisFeedbackDialogOpen = true;

      try {
        const { data } = await api.holdings.compareHoldings(this.houseFundId, this.refDate);

        this.analyzingErrors = data.errorsList ?? [];
        this.analysisStatus = data.status;
      } catch (error) {
        this.analysisStatus = 'ERROR';
        this.analyzingErrors = getApiErrors(error);
      } finally {
        this.isAnalyzingHoldings = false;
        this.fetchData();
      }
    },

    async approveHoldings() {
      this.isApprovingHoldings = true;

      try {
        await api.holdings.approveHoldings(this.details?._id, this.analyzingErrors);
        this.isAnalysisFeedbackDialogOpen = false;
        this.fetchData();
      } catch (error) {
        const errorList = getApiErrors(error);

        this.errorTitle = 'Algo de errado aconteceu!';
        this.errorMessage = 'Não foi possível aprovar a posição com ressalvas.';
        this.errorList = errorList;
        this.isErrorDialogOpen = true;
      } finally {
        this.isApprovingHoldings = false;
      }
    },

    async reproveHoldings() {
      this.isApprovingHoldings = true;

      try {
        await api.holdings.reproveHoldings(this.details?._id);
        this.isAnalysisFeedbackDialogOpen = false;
        this.fetchData();
      } catch (error) {
        const errorList = getApiErrors(error);

        this.errorTitle = 'Algo de errado aconteceu!';
        this.errorMessage = 'Não foi possível descartar as ressalvas.';
        this.errorList = errorList;
        this.isErrorDialogOpen = true;
      } finally {
        this.isApprovingHoldings = false;
      }
    },

    async fetchRiskData() {
      try {
        const [{ data: varDetails }, { data: stressDetails }, { data: liquidityDetails }] = await Promise.all([
          api.risk.var.getVarDetails(this.refDate, this.houseFundId),
          api.risk.stress.getStressDetails(this.refDate, this.houseFundId),
          api.risk.liquidity.getRegulatorCompliance(this.refDate, this.houseFundId),
        ]);

        const varData = varDetails.var[0];
        const stressData = orderBy(stressDetails.stress, 'percentageStress', 'desc');
        const liquidityData = liquidityDetails.results[0]?.regulatorCompliance;

        this.riskData = { varData, stressData, liquidityData };
      } catch (error) {
        if (error.response?.status === 404) {
          return;
        }

        this.$store.dispatch('alert/showAlert', {
          errorList: [error.response?.data.message ?? error.message],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>
