<template>
  <v-card>
    <v-card-title>
      Contas a Pagar/Receber

      <v-spacer />

      <v-badge overlap color="info" :content="filterCount" :value="filterCount > 0">
        <v-btn small rounded color="primary" @click="isFiltersDialogOpen = true">
          <v-icon small left>
            mdi-filter
          </v-icon>

          Filtros
        </v-btn>
      </v-badge>
    </v-card-title>

    <v-card-subtitle>
      Clique no box para expandir os detalhes
    </v-card-subtitle>

    <div class="px-4 pb-4">
      <v-container fluid class="overview-container" @click="areDetailsExpanded = !areDetailsExpanded">
        <v-row no-gutters>
          <v-col cols="2">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Registros
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ filteredItems.length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="2">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Valor total
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalValue < 0 }">
                  {{ formatMoney(overview.totalValue) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- <v-col cols="12" sm="6">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Atrib. perf. Mensal
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalMtdPerf < 0 }">
                  {{ formatPerformance(overview.totalMtdPerf) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="6">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  Atrib. perf. Anual
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalYtdPerf < 0 }">
                  {{ formatPerformance(overview.totalYtdPerf) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col> -->

          <v-col cols="12" sm="2">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  %PL total
                </v-list-item-title>

                <v-list-item-subtitle :class="{ 'error--text': overview.totalPercValue < 0 }">
                  {{ formatPercentage(overview.totalPercValue) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-expand-transition>
      <div v-show="areDetailsExpanded">
        <holding-pnr-table
          dense
          fixed-header
          :mobile-breakpoint="0"

          :items="filteredItems"

          must-sort
          sort-by="value"
          sort-desc

          :items-per-page="-1"
          hide-default-footer
        />
      </div>
    </v-expand-transition>

    <v-dialog v-model="isFiltersDialogOpen" max-width="600">
      <v-card>
        <v-card-title>
          Filtros
        </v-card-title>

        <v-container fluid class="pt-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Descrição"
                hide-details

                clearable
                v-model="filters.description"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <filters-autocomplete
                label="Origem"
                hide-details
                show-select-all

                multiple
                clearable
                small-chips
                deletable-chips

                :items="filterOptions.origin"
                v-model="filters.origin"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <filters-autocomplete
                label="Tipo"
                hide-details
                show-select-all

                multiple
                clearable
                small-chips
                deletable-chips

                :items="filterOptions.pnRType"
                v-model="filters.pnRType"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <filters-autocomplete
                label="Data de referência"
                hide-details
                show-select-all

                multiple
                clearable
                small-chips
                deletable-chips

                :items="filterOptions.releaseDate"
                v-model="filters.releaseDate"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <filters-autocomplete
                label="Vencimento"
                hide-details
                show-select-all

                multiple
                clearable
                small-chips
                deletable-chips

                :items="filterOptions.settlementDate"
                v-model="filters.settlementDate"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn small color="primary" @click="isFiltersDialogOpen = false">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { sumBy, uniq } from 'lodash';

import {
  formatMoney,
  formatDate,
  formatNumber,
  formatPercentage,
  formatPerformance,
} from '@/utils/format-utils';

import HoldingPnrTable from './HoldingPnrTable.vue';
import FiltersAutocomplete from './FiltersAutocomplete.vue';

function formatPnrType(type) {
  switch (type) {
    case 'FIXED_SETTLEMENT': return 'Fixo';
    case 'PROVISION': return 'Provisão';
    default: return type;
  }
}

export default {
  name: 'HoldingPnr',

  components: {
    HoldingPnrTable,
    FiltersAutocomplete,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    areDetailsExpanded: false,
    isFiltersDialogOpen: false,

    filters: {
      description: '',
      origin: [],
      pnRType: [],
      releaseDate: [],
      settlementDate: [],
    },
  }),

  computed: {
    filterCount: (vm) => Object.values(vm.filters).filter((val) => (Array.isArray(val) ? val.length > 0 : !!val)).length,

    filterOptions: (vm) => ({
      origin: uniq(vm.items.map((item) => item.origin)).sort(),
      pnRType: uniq(vm.items.map((item) => item.pnRType)).sort().map(
        (value) => ({ value, text: formatPnrType(value) }),
      ),
      releaseDate: uniq(vm.items.map((item) => item.releaseDate)).sort().reverse().map(
        (value) => ({ value, text: formatDate(value) }),
      ),
      settlementDate: uniq(vm.items.map((item) => item.settlementDate)).sort().reverse().map(
        (value) => ({ value, text: formatDate(value) }),
      ),
    }),

    filteredItems: (vm) => vm.items.filter((item) => {
      const descQuery = vm.filters.description ?? '';

      const rules = [
        !descQuery || item.description?.toLowerCase().includes(descQuery.toLowerCase()),
        !vm.filters.origin.length || vm.filters.origin.includes(item.origin),
        !vm.filters.pnRType.length || vm.filters.pnRType.includes(item.pnRType),
        !vm.filters.releaseDate.length || vm.filters.releaseDate.includes(item.releaseDate),
        !vm.filters.settlementDate.length || vm.filters.settlementDate.includes(item.settlementDate),
      ];

      return rules.every(Boolean);
    }),

    overview: (vm) => ({
      totalValue: sumBy(vm.filteredItems, 'value'),
      // totalMtdPerf: sumBy(vm.filteredItems, 'mtdPerf'),
      // totalYtdPerf: sumBy(vm.filteredItems, 'ytdPerf'),
      totalPercValue: sumBy(vm.filteredItems, 'percValue'),
    }),
  },

  methods: {
    formatMoney,
    formatDate,
    formatNumber,
    formatPercentage,
    formatPnrType,
    formatPerformance,
  },
};
</script>

<style lang="scss" scoped>
.overview-container {
  background: #eeee;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}
</style>
