<template>
  <v-container fluid>
    <v-row>
      <template v-for="item of overviewItems">
        <v-col cols="12" sm="4" :key="item.key">
          <v-card outlined :loading="isLoading">
            <v-card-title class="justify-center text-h4 font-weight-black">
              {{ item.count }}
            </v-card-title>

            <v-card-subtitle class="text-center text-subtitle-1 font-weight-light">
              {{ item.text }}
            </v-card-subtitle>

            <v-divider />

            <v-btn small block text color="primary" :disabled="!item.count" @click="() => showListDialog(item)">
              Ver lista
            </v-btn>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <v-dialog v-model="isDialogOpen" max-width="720" scrollable>
      <v-card>
        <v-card-title>
          {{ dialogTitle}}
        </v-card-title>

        <v-card-subtitle>
          {{ dialogSubtitle }}
        </v-card-subtitle>

        <v-card-text>
          <holdings-table
            dense
            fixed-header

            :items="selectedListItems"
            :columns="tableColums"

            must-sort
            sort-by="refDate"
            sort-desc

            hide-default-footer
            :items-per-page="-1"

            @click:analyze="(item) => $emit('click:analyze', item)"
            @click:calculate="(item) => $emit('click:calculate', item)"
            @click:upload="() => $emit('click:upload')"
          />
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn text color="primary" @click="isDialogOpen = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import HoldingsTable from './HoldingsTable.vue';

export default {
  name: 'HoldingsOverview',

  components: {
    HoldingsTable,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isDialogOpen: false,
    dialogTitle: '',
    dialogSubtitle: '',
    selectedListKey: [],

    tableColums: ['actions', 'shortName', 'refDate', 'status'],
  }),

  computed: {
    selectedListItems: (vm) => vm.data?.[vm.selectedListKey]?.list ?? [],

    overviewItems: (vm) => [
      {
        key: 'reproved',
        text: 'Reprovados',
        dialogTitle: 'Posições reprovadas',
        dialogSubtitle: 'Posições com informações divergentes do XML oficial Anbima',
        count: vm.data?.reproved?.total ?? 0,
      },

      {
        key: 'approved',
        text: 'Aprovados',
        dialogTitle: 'Posições aprovadas',
        dialogSubtitle: 'Posições com informações iguais ao XML oficial Anbima',
        count: vm.data?.approved?.total ?? 0,
      },

      {
        key: 'pending',
        text: 'Pendentes',
        dialogTitle: 'Posições pendentes',
        dialogSubtitle: 'Posições ainda não comparadas com o XML oficial Anbima',
        count: vm.data?.pending?.total ?? 0,
      },
    ],
  },

  watch: {
    isDialogOpen(val) {
      if (!val) {
        this.dialogTitle = '';
        this.dialogSubtitle = '';
        this.selectedListKey = '';
      }
    },
  },

  methods: {
    showListDialog(data) {
      this.dialogTitle = data.dialogTitle;
      this.dialogSubtitle = data.dialogSubtitle;
      this.selectedListKey = data.key;

      this.isDialogOpen = true;
    },
  },
};
</script>
