var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","content-class":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipEvents = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"primary","to":("/holdings/" + (item.houseFundId) + "?refDate=" + (item.refDate.substring(0, 10)))}},'v-btn',tooltipAttrs,false),tooltipEvents),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Ver detalhes ")]},proxy:true}],null,true)}),_c('v-tooltip',{attrs:{"right":"","content-class":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipEvents = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"indigo","disabled":_vm.readonly},on:{"click":function($event){return _vm.$emit('click:calculate', item)}}},'v-btn',tooltipAttrs,false),tooltipEvents),[_c('v-icon',[_vm._v(" mdi-calculator-variant-outline ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Calcular carteira ")]},proxy:true}],null,true)}),_c('v-tooltip',{attrs:{"right":"","content-class":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipEvents = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":item.hasOfficialHolding ? 'info' : 'error',"disabled":_vm.readonly},on:{"click":function($event){return _vm.$emit(item.hasOfficialHolding ? 'click:analyze' : 'click:upload', item)}}},'v-btn',tooltipAttrs,false),tooltipEvents),[_c('v-icon',[_vm._v(" "+_vm._s(item.hasOfficialHolding ? 'mdi-clipboard-pulse-outline' : 'mdi-clipboard-alert-outline')+" ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(item.hasOfficialHolding ? 'Validar posição' : 'O XML oficial ainda não foi importado')+" ")]},proxy:true}],null,true)})]}},{key:"item.refDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.refDate))+" ")]}},{key:"item.netValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.netValue || item.calculatedNetValue))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatQuota(item.price || item.calculatedPrice))+" ")]}},{key:"item.shareQuantity",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.shareQuantity < 0 }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.shareQuantity))+" ")])]}},{key:"item.dailyReturn",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.dailyReturn < 0 }},[_vm._v(" "+_vm._s(_vm.formatReturn(item.dailyReturn))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('holdings-status-indicator',{attrs:{"status":item.status}})]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }