<template>
  <contextual-dialog
    v-bind="$attrs"
    v-on="$listeners"

    scrollable
    :value="value"
    :loading="loading"
    :max-width="hasError ? 600 : 400"

    :icon="icon"
    :icon-color="iconColor"
    :title="title"
    :message="message"
  >
    <template v-if="selectedItem">
      <p>Ref: {{ houseFundInfo }}</p>
    </template>

    <template v-if="status === 'ERROR'">
      <slot name="actions-error" />
    </template>

    <template v-if="status === 'APPROVED_WITH_WARNINGS'">
      <slot name="actions-approved_with_warnings" />
    </template>

    <template v-if="hasError">
      <v-divider class="mx-4" />

      <v-card-text class="text-left">
        <template v-for="(error, index) of errorList">
          <div class="pa-2" :key="index">
            <template v-if="typeof error === 'string'">
              <p :key="index" class="text-body-2 mb-0 mt-4 px-4">
                <v-icon color="error" left>
                  mdi-alert-circle-outline
                </v-icon>

                <span style="vertical-align: middle">
                  {{ error }}
                </span>
              </p>
            </template>

            <template v-else>
              <p class="text-subtitle-2 mb-0">{{ error.errorClass }}</p>
              <p class="text-caption mb-2">
                {{ error.message }}

                <span v-if="error.asset">
                  [{{ error.asset }}]
                </span>
              </p>

              <table class="divergence-table">
                <tbody>
                  <tr>
                    <th>Calculado</th>
                    <th>Oficial</th>
                    <th>Divergência</th>
                  </tr>

                  <tr>
                    <td>{{ error.calculated }}</td>
                    <td>{{ error.official }}</td>
                    <td>{{ error.divergence }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </template>
      </v-card-text>
    </template>
  </contextual-dialog>
</template>

<script>
import ContextualDialog from '@/components/global/ContextualDialog.vue';

export default {
  name: 'HoldingAnalysisFeedback',

  components: {
    ContextualDialog,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    errorList: {
      type: Array,
      default: () => [],
    },

    selectedItem: {
      type: Object,
      default: null,
    },

    status: {
      type: String,
      default: 'IDLE',
    },
  },

  computed: {
    hasError: (vm) => vm.errorList?.length > 0,
    houseFundInfo: (vm) => `${vm.selectedItem?.shortName} ${vm.selectedItem?.refDate.substring(0, 10)}`,

    icon: (vm) => {
      switch (vm.status) {
        case 'LOADING': return 'mdi-clipboard-pulse-outline';
        case 'APPROVED': return 'mdi-check';
        case 'APPROVED_WITH_WARNINGS': return 'mdi-check';
        case 'ERROR': return 'mdi-exclamation-thick';
        default: return '';
      }
    },

    iconColor: (vm) => {
      switch (vm.status) {
        case 'LOADING': return 'grey';
        case 'APPROVED': return 'success';
        case 'APPROVED_WITH_WARNINGS': return 'success';
        case 'ERROR': return 'error';
        default: return '';
      }
    },

    title: (vm) => {
      switch (vm.status) {
        case 'LOADING': return 'Validando posição';
        case 'APPROVED': return 'Posição aprovada';
        case 'APPROVED_WITH_WARNINGS': return 'Posição aprovada com ressalvas';
        case 'ERROR': return 'Posição reprovada';
        default: return '';
      }
    },

    message: (vm) => {
      switch (vm.status) {
        case 'LOADING': return 'Aguarde enquanto a posição é comparada com os dados da administradora...';
        case 'APPROVED': return 'Os dados calculados batem com os informados pela administradora!';
        case 'APPROVED_WITH_WARNINGS': return 'A posição foi aprovada com ressalvas já conhecidas.';
        case 'ERROR': return 'Os dados calculados divergem dos informados pela administradora.';
        default: return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.divergence-table {
  width: 100%;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
</style>
